import React, { useRef } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import Modal from "./modal";
import { useSpring, animated } from "@react-spring/web";
import { InstaIcon } from "./instagram-icon";
import { Logo } from "./logo";

const Menu = (props: { color: "WHITE" | "BLACK" }) => {
  const [showMenu, setShowmenu] = React.useState(false);
  const modalRef = useRef(null);
  const toggle = () => {
    setShowmenu(!showMenu);
  };

  const enter = (e: any) => {
    if (e.key === "Enter") {
      toggle();
      if (modalRef?.current) {
        const div: any = modalRef?.current;
        div.focus();
      }
    }
  };

  const fade = useSpring({
    display: "flex",
    opacity: showMenu ? 1 : 0,
    transform: showMenu ? "translate3d(0,0,0)" : "translate3d(0,-100%,0)",
  });

  return (
    <>
      <MenuList>
        <li>
          <NavLink exact to="/foto">
            foto
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/om-meg">
            om meg
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/blogg">
            blogg
          </NavLink>
        </li>
        <li>
          <InstaIcon color={props.color} width={20} height={20} />
        </li>
      </MenuList>
      <Burger color={props.color} onKeyUp={enter} onClick={toggle} tabIndex={0}>
        <div />
        <div />
        <div />
      </Burger>
      <Modal>
        <animated.div className="modal-content" style={fade}>
          <ModalMenu>
            <Logo color="BLACK" style={{ margin: "0 2em" }} />
            {/*
            <LogoLink to="/">
              <img src="/logo-main.png" alt="Glenn Wedin" />
            </LogoLink>
  */}

            <ul>
              <li>
                <NavLink ref={modalRef} exact to="/foto" onClick={toggle}>
                  foto
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/om-meg" onClick={toggle}>
                  om meg
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/blogg" onClick={toggle}>
                  blogg
                </NavLink>
              </li>
              <li>
                <InstaIcon width={30} height={30} />
              </li>
              <li>
                <Close
                  tabIndex={0}
                  title="Lukk"
                  onKeyUp={enter}
                  onClick={toggle}
                />
              </li>
            </ul>
          </ModalMenu>
        </animated.div>
      </Modal>
    </>
  );
};

const MenuList = styled.ul`
  display: none;
  font-weight: 100;
  @media (min-width: 800px) {
    display: block;
    margin: 2px 0 0;
    padding: 0;
    li {
      margin-left: 2vw;
      display: inline-block;
      vertical-align: middle;
    }
    a {
      color: inherit;
      text-decoration: none;
      border: none;
    }

    .active {
      border-bottom: 1px solid;
    }
  }
`;

const Burger = styled.div`
  display: inline-block;
  cursor: pointer;
  margin: 4px 0;
  @media (min-width: 800px) {
    display: none;
  }
  div {
    width: 30px;
    height: 2px;
    background-color: ${(props) =>
      props.color === "BLACK" ? props.color : "#fff"};
    margin: 6px 0;
    transition: 0.4s;
  }
  div:nth-child(2) {
    width: 25px;
    margin: 6px 0 0 5px;
  }
  a {
    border: none;
  }
`;

const ModalMenu = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: "Oswald", sans-serif;
  img {
    width: 80%;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    align-self: flex-start;
    font-weight: 100;
  }
  li {
    margin: 20px auto;
    font-size: 2rem;
  }
  a {
    color: inherit;
    text-decoration: none;
    text-align: center;
    border: none;
  }
`;

export const Close = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;

  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const LogoLink = styled(Link)`
  border: none;
`;

export default Menu;
