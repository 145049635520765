import React, { useState, useEffect } from "react";
import { useSpring } from "@react-spring/web";

export const useFadeIn = (time?: number) => {
  const [show, setShow] = useState<boolean>(false);

  const fadeTransition = useSpring({
    opacity: show ? 1 : 0,
    transform: show ? "translate3d(0,0,0)" : "translate3d(0,-25px,0)",
  });
  const fakeTransition = useSpring({
    opacity: show ? 1 : 1,
  });

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, time || 400);
  }, []);

  return { fadeIn: fadeTransition };
};
