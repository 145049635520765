import React from "react";
import styled from "styled-components";
import Menu from "./menu";
import { Link } from "react-router-dom";
import { animated } from "@react-spring/web";
import { useFadeIn } from "./hooks/useFadeIn";
import { LogoSimple } from "./logo-simple";

const PageHeader = (props: {
  color: "WHITE" | "BLACK";
  showlogo?: boolean;
}) => {
  const fadeIn = useFadeIn();
  return (
    <TopBar color={props.color}>
      {props.showlogo ? (
        <Title to="/">
          <LogoSimple />
        </Title>
      ) : (
        <div />
      )}

      <Menu {...props} />
    </TopBar>
  );
};

const TopBar = styled(animated.div)`
    grid-column: 1 / 7;
    display: flex;
    justify-content: space-between;
    font-family: 'Oswald', sans-serif;
    margin: 1em 2em;
    font-size: 1em;
    position: relative;
    height: 40px;
    z-index: 2;
    color:${(props: any) => props.color};

    svg {
        width: 40px;
        margin-top: 8px;
        path{
        fill: ${(props: any) => (props.color ? props.color : "#fff")};
        }
    }
}
`;
const Title = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #000;
  border: none;
`;

export default PageHeader;
