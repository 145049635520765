import React from 'react';

export const LogoSimple = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 190.69 100.44">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Group_1" data-name="Group 1">
                    <g id="G">
                        <path
                            d="M50.61,128.32h-17q-10.16,0-16-5.93t-5.85-16.25V50.06q0-10.31,5.85-16.24t16-5.94h17q10.16,0,16.09,5.94t5.93,16.24v7c0,1.25-.57,1.88-1.71,1.88H66.39c-1.15,0-1.72-.63-1.72-1.88V50.38q0-7.5-3.75-11.49T50,34.91H34.21Q27,34.91,23.27,39T19.53,50.38v55.45q0,7.33,3.74,11.4t10.94,4.06H50q7.19,0,10.94-4t3.75-11.48V85.21a1.25,1.25,0,0,0-1.41-1.41h-18a1.52,1.52,0,0,1-1.72-1.72V78.49a1.52,1.52,0,0,1,1.72-1.72H69.67c2,0,3,1,3,3.13v26.24q0,10.31-5.93,16.25T50.61,128.32Z"
                            transform="translate(-11.72 -27.88)"
                            fill="#3d3f43"
                        />
                    </g>
                    <g id="W">
                        <path
                            d="M119.68,128.32h-7.81a2.12,2.12,0,0,1-2.18-1.87L88.29,29.76c-.32-1.25.2-1.88,1.56-1.88H95a1.93,1.93,0,0,1,2,1.88l18.59,89.66h.78L140.15,29.6a1.87,1.87,0,0,1,2-1.72h5.93a2,2,0,0,1,2.19,1.72l23.9,89.82H175l18.43-89.66a2,2,0,0,1,2.19-1.88h5.31c1.25,0,1.72.63,1.4,1.88l-21.39,96.69a1.93,1.93,0,0,1-2,1.87h-8a2.13,2.13,0,0,1-2.19-1.87l-23.12-88.1H145l-23.28,88.1C121.4,127.7,120.72,128.32,119.68,128.32Z"
                            transform="translate(-11.72 -27.88)"
                            fill="#3d3f43"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
