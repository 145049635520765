import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
const Modal = (props: any) => {
    const [el, setEl] = useState<any>(null);
    useEffect(() => {
        let element: any = document.getElementById('modal') as Element;
        setEl(element);
    }, []);
    if (el) {
        return ReactDOM.createPortal(props.children, el);
    }
    return null;
};
export default Modal;
