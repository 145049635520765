import React from "react";
import styled from "styled-components";

type Color = "WHITE" | "BLACK";

export const Logo = (props: {
  color: Color;
  showPhoto?: boolean;
  style?: any;
}) => {
  return (
    <>
      {props.showPhoto && <Photo title="Meg" src="/glenntek.jpg" />}

      <Svg
        style={props.style}
        color={props.color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 764.26 136.71"
      >
        <g id="c" data-name="Layer 1">
          <g>
            <g>
              <g>
                <path
                  className="e"
                  d="M115.75,83.57h-13.39c-5.32,0-9.52-1.5-12.59-4.52-3.07-3.01-4.61-7.13-4.61-12.36V24.01c0-5.23,1.54-9.35,4.61-12.36,3.07-3.01,7.27-4.52,12.59-4.52h13.39c5.32,0,9.54,1.51,12.65,4.52,3.11,3.01,4.67,7.13,4.67,12.36v5.35c0,.95-.45,1.43-1.35,1.43h-3.56c-.9,0-1.35-.48-1.35-1.43v-5.11c0-3.8-.98-6.72-2.95-8.74-1.97-2.02-4.83-3.03-8.6-3.03h-12.41c-3.77,0-6.63,1.03-8.6,3.09-1.97,2.06-2.95,4.95-2.95,8.68v42.2c0,3.73,.98,6.62,2.95,8.68,1.97,2.06,4.83,3.09,8.6,3.09h12.41c3.77,0,6.63-1.01,8.6-3.03,1.97-2.02,2.95-4.93,2.95-8.74v-15.69c0-.71-.37-1.07-1.11-1.07h-14.13c-.9,0-1.35-.43-1.35-1.31v-2.73c0-.87,.45-1.31,1.35-1.31h19.16c1.55,0,2.33,.79,2.33,2.38v19.97c0,5.23-1.56,9.35-4.67,12.36-3.11,3.01-7.33,4.52-12.65,4.52Z"
                />
                <path
                  className="e"
                  d="M156.78,83.57h-3.56c-.82,0-1.23-.48-1.23-1.43V1.43c0-.95,.41-1.43,1.23-1.43h3.56c.9,0,1.35,.48,1.35,1.43V82.14c0,.95-.45,1.43-1.35,1.43Z"
                />
                <path
                  className="e"
                  d="M218.81,83.57h-25.68c-5.32,0-9.52-1.5-12.59-4.52-3.07-3.01-4.61-7.13-4.61-12.36v-26.87c0-5.23,1.54-9.35,4.61-12.36,3.07-3.01,7.27-4.52,12.59-4.52h10.56c5.32,0,9.54,1.51,12.65,4.52,3.11,3.01,4.67,7.13,4.67,12.36v15.1c0,.95-.49,1.43-1.47,1.43h-36.36c-.74,0-1.11,.36-1.11,1.07v9.15c0,3.73,.98,6.6,2.95,8.62,1.97,2.02,4.83,3.03,8.6,3.03h25.18c.9,0,1.35,.44,1.35,1.31v2.73c0,.87-.45,1.31-1.35,1.31Zm-35.63-31.98h30.59c.74,0,1.11-.36,1.11-1.07v-10.58c0-3.72-.98-6.6-2.95-8.62-1.97-2.02-4.83-3.03-8.6-3.03h-9.7c-3.77,0-6.63,1.01-8.6,3.03-1.97,2.02-2.95,4.89-2.95,8.62v10.58c0,.71,.37,1.07,1.11,1.07Z"
                />
                <path
                  className="e"
                  d="M242.4,83.57h-3.56c-.82,0-1.23-.48-1.23-1.43V24.37c0-.95,.41-1.43,1.23-1.43h3.56c.9,0,1.35,.48,1.35,1.43v4.76h.49c2.29-4.12,6.55-6.18,12.78-6.18h8.11c5.24,0,9.38,1.51,12.41,4.52,3.03,3.01,4.55,7.13,4.55,12.36v42.32c0,.95-.41,1.43-1.23,1.43h-3.56c-.9,0-1.35-.48-1.35-1.43V40.06c0-3.72-.98-6.62-2.95-8.68-1.97-2.06-4.83-3.09-8.6-3.09h-7.99c-3.93,0-7.02,1.01-9.28,3.03-2.25,2.02-3.38,4.89-3.38,8.62v42.2c0,.95-.45,1.43-1.35,1.43Z"
                />
                <path
                  className="e"
                  d="M304.68,83.57h-3.56c-.82,0-1.23-.48-1.23-1.43V24.37c0-.95,.41-1.43,1.23-1.43h3.56c.9,0,1.35,.48,1.35,1.43v4.76h.49c2.29-4.12,6.55-6.18,12.78-6.18h8.11c5.24,0,9.38,1.51,12.41,4.52,3.03,3.01,4.55,7.13,4.55,12.36v42.32c0,.95-.41,1.43-1.23,1.43h-3.56c-.9,0-1.35-.48-1.35-1.43V40.06c0-3.72-.98-6.62-2.95-8.68-1.97-2.06-4.83-3.09-8.6-3.09h-7.99c-3.93,0-7.02,1.01-9.28,3.03-2.25,2.02-3.38,4.89-3.38,8.62v42.2c0,.95-.45,1.43-1.35,1.43Z"
                />
              </g>
              <g>
                <path
                  className="e"
                  d="M404.51,83.58h-9.46c-1.15,0-1.84-.55-2.09-1.66l-15.72-73.11c-.25-1.11,.29-1.66,1.6-1.66h6.63c1.23,0,1.88,.56,1.97,1.66l12.41,65.5h.74l16.58-65.62c.25-1.03,.9-1.55,1.97-1.55h7.37c1.06,0,1.72,.52,1.97,1.55l16.83,65.62h.74l12.28-65.38c.25-1.19,.94-1.78,2.09-1.78h6.63c1.31,0,1.84,.59,1.6,1.78l-15.85,72.99c-.25,1.11-.9,1.66-1.97,1.66h-9.46c-1.15,0-1.84-.55-2.09-1.66l-16.09-63.84h-.49l-16.22,63.84c-.25,1.11-.9,1.66-1.97,1.66Z"
                />
                <path
                  className="e"
                  d="M523.3,83.58h-26.04c-5.65,0-10.09-1.55-13.33-4.64-3.24-3.09-4.85-7.41-4.85-12.96v-25.44c0-5.55,1.62-9.87,4.85-12.96,3.23-3.09,7.68-4.64,13.33-4.64h10.32c5.65,0,10.07,1.55,13.27,4.64s4.79,7.41,4.79,12.96v15.1c0,1.19-.57,1.78-1.72,1.78h-34.77c-.82,0-1.23,.36-1.23,1.07v7.25c0,6.89,3.44,10.34,10.32,10.34h25.06c1.15,0,1.72,.56,1.72,1.66v4.28c0,1.03-.57,1.55-1.72,1.55Zm-34.15-32.45h26.54c.82,0,1.23-.4,1.23-1.19v-9.15c0-6.81-3.44-10.22-10.32-10.22h-8.35c-6.88,0-10.32,3.41-10.32,10.22v9.15c0,.79,.41,1.19,1.23,1.19Z"
                />
                <path
                  className="e"
                  d="M564.46,83.58h-7.25c-5.57,0-9.91-1.55-13.02-4.64-3.11-3.09-4.67-7.41-4.67-12.96v-25.44c0-5.55,1.55-9.87,4.67-12.96,3.11-3.09,7.45-4.64,13.02-4.64h7.25c6.39,0,10.61,2.1,12.65,6.3h.49V1.68c0-1.11,.53-1.66,1.6-1.66h5.65c1.06,0,1.6,.56,1.6,1.66V81.92c0,1.11-.53,1.66-1.6,1.66h-5.65c-1.07,0-1.6-.55-1.6-1.66v-4.64h-.49c-2.05,4.2-6.27,6.3-12.65,6.3Zm13.14-18.54v-23.54c0-3.49-.98-6.18-2.95-8.08s-4.79-2.85-8.48-2.85h-7.49c-6.8,0-10.2,3.45-10.2,10.34v24.73c0,6.89,3.4,10.34,10.2,10.34h7.49c3.69,0,6.51-.95,8.48-2.85s2.95-4.6,2.95-8.08Z"
                />
                <path
                  className="e"
                  d="M604.01,12.97V1.8c0-1.11,.53-1.66,1.6-1.66h5.65c1.15,0,1.72,.56,1.72,1.66V12.97c0,1.11-.57,1.66-1.72,1.66h-5.65c-1.07,0-1.6-.55-1.6-1.66Zm0,68.95V24.62c0-1.11,.53-1.66,1.6-1.66h5.65c1.15,0,1.72,.56,1.72,1.66v57.3c0,1.11-.57,1.66-1.72,1.66h-5.53c-1.15,0-1.72-.55-1.72-1.66Z"
                />
                <path
                  className="e"
                  d="M637.79,83.58h-5.53c-1.15,0-1.72-.55-1.72-1.66V24.62c0-1.11,.57-1.66,1.72-1.66h5.53c1.15,0,1.72,.56,1.72,1.66v4.64h.49c2.05-4.2,6.22-6.3,12.53-6.3h6.88c5.57,0,9.91,1.55,13.02,4.64,3.11,3.09,4.67,7.41,4.67,12.96v41.37c0,1.11-.57,1.66-1.72,1.66h-5.53c-1.15,0-1.72-.55-1.72-1.66V40.91c0-6.89-3.44-10.34-10.32-10.34h-6.88c-3.69,0-6.51,.95-8.48,2.85s-2.95,4.6-2.95,8.08v40.42c0,1.11-.57,1.66-1.72,1.66Z"
                />
              </g>
            </g>
            <g>
              <path
                className="e"
                d="M1.6,130.8H.41c-.27,0-.41-.16-.41-.48v-24.53c0-.32,.14-.48,.41-.48H13.02c.33,0,.49,.15,.49,.44v.91c0,.29-.16,.44-.49,.44H2.46c-.27,0-.41,.12-.41,.36v9.63c0,.21,.14,.32,.41,.32H11.92c.3,0,.45,.15,.45,.44v.95c0,.29-.15,.44-.45,.44H2.46c-.27,0-.41,.11-.41,.32v10.78c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M26.54,130.8h-2.7c-1.75,0-3.13-.5-4.14-1.51-1.01-1-1.52-2.38-1.52-4.12v-14.11c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v14.03c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h2.66c1.31,0,2.34-.34,3.09-1.01,.75-.67,1.13-1.63,1.13-2.87v-14.07c0-.32,.15-.48,.45-.48h1.19c.27,0,.41,.16,.41,.48v19.26c0,.32-.14,.48-.41,.48h-1.19c-.3,0-.45-.16-.45-.48v-1.58h-.16c-.71,1.37-2.13,2.06-4.26,2.06Z"
              />
              <path
                className="e"
                d="M40.95,130.8h-1.19c-.27,0-.41-.16-.41-.48v-26.91c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M49.55,130.8h-1.19c-.27,0-.41-.16-.41-.48v-26.91c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M63.1,130.8h-7.13c-.3,0-.45-.14-.45-.44v-.91c0-.26,.15-.4,.45-.4h6.92c2.46,0,3.69-.92,3.69-2.77v-.95c0-1.72-.76-2.84-2.29-3.37l-5.12-1.9c-1.39-.53-2.38-1.15-2.97-1.86-.59-.71-.88-1.7-.88-2.97,0-3.09,1.76-4.64,5.28-4.64h6.63c.3,0,.45,.15,.45,.44v.91c0,.26-.15,.4-.45,.4h-6.43c-1.12,0-1.96,.24-2.52,.71-.56,.48-.84,1.03-.84,1.66v.91c0,.74,.21,1.32,.63,1.74,.42,.42,1.07,.78,1.94,1.07l5.12,1.98c1.42,.45,2.37,1.12,2.85,2,.48,.89,.72,2.12,.72,3.71s-.46,2.76-1.37,3.53c-.92,.77-2.33,1.15-4.24,1.15Z"
              />
              <path
                className="e"
                d="M74.9,112.38h-2.5c-.3,0-.45-.13-.45-.4v-.95c0-.29,.15-.44,.45-.44h2.5c.25,0,.37-.12,.37-.36v-3.21c0-.32,.15-.48,.45-.48h1.19c.3,0,.45,.16,.45,.48v3.21c0,.24,.12,.36,.37,.36h4.87c.3,0,.45,.15,.45,.44v.95c0,.26-.15,.4-.45,.4h-4.87c-.25,0-.37,.12-.37,.36v12.48c0,1.22,.35,2.15,1.06,2.81,.71,.66,1.71,.99,2.99,.99h1.6c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44h-1.76c-1.8,0-3.25-.49-4.34-1.47-1.09-.98-1.64-2.32-1.64-4.04v-12.56c0-.24-.12-.36-.37-.36Z"
              />
              <path
                className="e"
                d="M96.15,130.8h-2.62c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h8.68c.27,0,.41,.16,.41,.48v19.26c0,.32-.14,.48-.41,.48h-1.19c-.3,0-.45-.16-.45-.48v-1.58h-.16c-.71,1.37-2.13,2.06-4.26,2.06Zm4.42-5.67v-12.4c0-.24-.14-.36-.41-.36h-6.47c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v8.8c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h2.66c1.31,0,2.34-.34,3.09-1.01,.75-.67,1.13-1.63,1.13-2.87Z"
              />
              <path
                className="e"
                d="M120.43,129.46v.91c0,.29-.15,.44-.45,.44h-5.9c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h5.9c.3,0,.45,.15,.45,.44v.95c0,.26-.15,.4-.45,.4h-5.73c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v8.8c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h5.73c.3,0,.45,.15,.45,.44Z"
              />
              <path
                className="e"
                d="M126.49,130.8h-1.19c-.27,0-.41-.16-.41-.48v-26.91c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48Zm10.16-20.21h1.27c.16,0,.27,.05,.33,.14,.05,.09,.03,.2-.08,.34l-6.27,9.19v.16l7,9.91c.11,.13,.14,.24,.08,.34-.06,.09-.16,.14-.33,.14h-1.27c-.41,0-.72-.16-.94-.48l-6.76-9.63c-.11-.21-.11-.42,0-.63l6.14-9.07c.16-.26,.44-.4,.82-.4Z"
              />
              <path
                className="e"
                d="M154.17,130.8h-.53c-.36,0-.46-.16-.33-.48l8.93-24.57c.11-.29,.29-.44,.53-.44h.57c.3,0,.38,.18,.25,.55l-8.89,24.49c-.11,.29-.29,.44-.53,.44Z"
              />
              <path
                className="e"
                d="M191.93,130.8h-5.16c-1.78,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-14.23c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h5.16c1.77,0,3.17,.5,4.2,1.51,1.02,1,1.54,2.38,1.54,4.12v1.94c0,.32-.15,.48-.45,.48h-1.15c-.3,0-.45-.16-.45-.48v-1.86c0-1.27-.33-2.24-1-2.91-.67-.67-1.63-1.01-2.89-1.01h-4.79c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v14.07c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h4.79c1.26,0,2.22-.34,2.89-1.01,.67-.67,1-1.64,1-2.91v-1.86c0-.32,.15-.48,.45-.48h1.15c.3,0,.45,.16,.45,.48v1.94c0,1.74-.51,3.12-1.54,4.12-1.02,1-2.42,1.51-4.2,1.51Z"
              />
              <path
                className="e"
                d="M205.03,130.8h-1.19c-.27,0-.41-.16-.41-.48v-26.91c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M220.67,130.8h-3.52c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v8.96c0,1.74-.52,3.12-1.56,4.12-1.04,1-2.44,1.51-4.22,1.51Zm-3.36-1.78h3.19c1.26,0,2.22-.34,2.89-1.01,.67-.67,1-1.63,1-2.87v-8.88c0-1.24-.33-2.2-1-2.87-.67-.67-1.63-1.01-2.89-1.01h-3.19c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v8.88c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01Z"
              />
              <path
                className="e"
                d="M240.29,130.8h-2.7c-1.75,0-3.13-.5-4.14-1.51-1.01-1-1.52-2.38-1.52-4.12v-14.11c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v14.03c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h2.66c1.31,0,2.34-.34,3.09-1.01,.75-.67,1.13-1.63,1.13-2.87v-14.07c0-.32,.15-.48,.45-.48h1.19c.27,0,.41,.16,.41,.48v19.26c0,.32-.14,.48-.41,.48h-1.19c-.3,0-.45-.16-.45-.48v-1.58h-.16c-.71,1.37-2.13,2.06-4.26,2.06Z"
              />
              <path
                className="e"
                d="M260.89,130.8h-2.74c-1.75,0-3.13-.5-4.14-1.51-1.01-1-1.52-2.38-1.52-4.12v-8.96c0-1.74,.5-3.12,1.52-4.12,1.01-1,2.39-1.51,4.14-1.51h2.74c2.1,0,3.54,.69,4.3,2.06h.16v-9.23c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48h-1.19c-.27,0-.41-.16-.41-.48v-1.58h-.16c-.76,1.37-2.2,2.06-4.3,2.06Zm4.46-5.67v-8.88c0-1.24-.38-2.2-1.13-2.87-.75-.67-1.78-1.01-3.09-1.01h-2.74c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v8.8c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h2.74c1.31,0,2.34-.34,3.09-1.01,.75-.67,1.13-1.63,1.13-2.87Z"
              />
              <path
                className="e"
                d="M285.17,130.8h-.53c-.36,0-.46-.16-.33-.48l8.93-24.57c.11-.29,.29-.44,.53-.44h.57c.3,0,.38,.18,.25,.55l-8.89,24.49c-.11,.29-.29,.44-.53,.44Z"
              />
              <path
                className="e"
                d="M326.69,105.33h1.15c.3,0,.45,.15,.45,.44v19.42c0,1.74-.51,3.12-1.54,4.12-1.02,1-2.42,1.51-4.2,1.51h-4.59c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-19.38c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v19.3c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h4.22c1.26,0,2.22-.34,2.89-1.01,.67-.67,1-1.64,1-2.91v-19.3c0-.32,.15-.48,.45-.48Z"
              />
              <path
                className="e"
                d="M341.39,130.8h-7.13c-.3,0-.45-.14-.45-.44v-.91c0-.26,.15-.4,.45-.4h6.92c2.46,0,3.69-.92,3.69-2.77v-.95c0-1.72-.76-2.84-2.29-3.37l-5.12-1.9c-1.39-.53-2.38-1.15-2.97-1.86-.59-.71-.88-1.7-.88-2.97,0-3.09,1.76-4.64,5.28-4.64h6.63c.3,0,.45,.15,.45,.44v.91c0,.26-.15,.4-.45,.4h-6.43c-1.12,0-1.96,.24-2.52,.71-.56,.48-.84,1.03-.84,1.66v.91c0,.74,.21,1.32,.63,1.74,.42,.42,1.07,.78,1.94,1.07l5.12,1.98c1.42,.45,2.37,1.12,2.85,2,.48,.89,.72,2.12,.72,3.71s-.46,2.76-1.37,3.53c-.92,.77-2.33,1.15-4.24,1.15Z"
              />
              <path
                className="e"
                d="M366.17,130.8h-8.56c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v5.03c0,.32-.16,.48-.49,.48h-12.12c-.25,0-.37,.12-.37,.36v3.05c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01h8.39c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44Zm-11.88-10.66h10.2c.25,0,.37-.12,.37-.36v-3.53c0-1.24-.33-2.2-.98-2.87-.66-.67-1.61-1.01-2.87-1.01h-3.23c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v3.53c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M374.03,130.8h-1.19c-.27,0-.41-.16-.41-.48v-19.26c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v1.66h.16c.3-.55,.83-1.05,1.6-1.49,.76-.44,1.71-.65,2.83-.65h1.6c.3,0,.45,.15,.45,.44v.95c0,.29-.15,.42-.45,.4h-1.92c-1.34,0-2.38,.4-3.13,1.19-.75,.79-1.13,1.86-1.13,3.21v13.55c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M396.39,107.27v-3.8c0-.29,.14-.44,.41-.44h1.23c.27,0,.41,.15,.41,.44v3.8c0,.32-.14,.48-.41,.48h-1.23c-.27,0-.41-.16-.41-.48Zm0,23.06v-19.26c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v19.26c0,.32-.14,.48-.41,.48h-1.23c-.27,0-.41-.16-.41-.48Z"
              />
              <path
                className="e"
                d="M406.38,130.8h-1.19c-.27,0-.41-.16-.41-.48v-19.26c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v1.58h.16c.76-1.37,2.18-2.06,4.26-2.06h2.7c1.75,0,3.13,.5,4.14,1.51,1.01,1,1.52,2.38,1.52,4.12v14.11c0,.32-.14,.48-.41,.48h-1.19c-.3,0-.45-.16-.45-.48v-14.03c0-1.24-.33-2.21-.98-2.89-.66-.69-1.61-1.03-2.87-1.03h-2.66c-1.31,0-2.34,.34-3.09,1.01-.75,.67-1.13,1.63-1.13,2.87v14.07c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M426.44,112.38h-2.5c-.3,0-.45-.13-.45-.4v-.95c0-.29,.15-.44,.45-.44h2.5c.25,0,.37-.12,.37-.36v-3.21c0-.32,.15-.48,.45-.48h1.19c.3,0,.45,.16,.45,.48v3.21c0,.24,.12,.36,.37,.36h4.87c.3,0,.45,.15,.45,.44v.95c0,.26-.15,.4-.45,.4h-4.87c-.25,0-.37,.12-.37,.36v12.48c0,1.22,.35,2.15,1.06,2.81,.71,.66,1.71,.99,2.99,.99h1.6c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44h-1.76c-1.8,0-3.25-.49-4.34-1.47-1.09-.98-1.64-2.32-1.64-4.04v-12.56c0-.24-.12-.36-.37-.36Z"
              />
              <path
                className="e"
                d="M453.63,130.8h-8.56c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v5.03c0,.32-.16,.48-.49,.48h-12.12c-.25,0-.37,.12-.37,.36v3.05c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01h8.39c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44Zm-11.88-10.66h10.2c.25,0,.37-.12,.37-.36v-3.53c0-1.24-.33-2.2-.98-2.87-.66-.67-1.61-1.01-2.87-1.01h-3.23c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v3.53c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M461.49,130.8h-1.19c-.27,0-.41-.16-.41-.48v-19.26c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v1.66h.16c.3-.55,.83-1.05,1.6-1.49,.76-.44,1.71-.65,2.83-.65h1.6c.3,0,.45,.15,.45,.44v.95c0,.29-.15,.42-.45,.4h-1.92c-1.34,0-2.38,.4-3.13,1.19-.75,.79-1.13,1.86-1.13,3.21v13.55c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M477.01,110.6h3.73c.33,0,.49,.15,.49,.44v.91c0,.29-.16,.44-.49,.44h-3.73c-.25,0-.37,.12-.37,.36v17.59c0,.32-.15,.48-.45,.48h-1.19c-.3,0-.45-.16-.45-.48v-17.59c0-.24-.12-.36-.37-.36h-2.62c-.3,0-.45-.14-.45-.44v-.91c0-.29,.15-.44,.45-.44h2.62c.25,0,.37-.12,.37-.36v-1.74c0-3.7,1.73-5.55,5.2-5.55h.98c.33,0,.49,.15,.49,.44v.91c0,.29-.16,.44-.49,.44h-.78c-1.23,0-2.09,.32-2.58,.95-.49,.63-.74,1.6-.74,2.89v1.66c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M493.07,130.8h-2.62c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h8.68c.27,0,.41,.16,.41,.48v19.26c0,.32-.14,.48-.41,.48h-1.19c-.3,0-.45-.16-.45-.48v-1.58h-.16c-.71,1.37-2.13,2.06-4.26,2.06Zm4.42-5.67v-12.4c0-.24-.14-.36-.41-.36h-6.47c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v8.8c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h2.66c1.31,0,2.34-.34,3.09-1.01,.75-.67,1.13-1.63,1.13-2.87Z"
              />
              <path
                className="e"
                d="M517.35,129.46v.91c0,.29-.15,.44-.45,.44h-5.9c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h5.9c.3,0,.45,.15,.45,.44v.95c0,.26-.15,.4-.45,.4h-5.73c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v8.8c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h5.73c.3,0,.45,.15,.45,.44Z"
              />
              <path
                className="e"
                d="M535.69,130.8h-8.56c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v5.03c0,.32-.16,.48-.49,.48h-12.12c-.25,0-.37,.12-.37,.36v3.05c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01h8.39c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44Zm-11.88-10.66h10.2c.25,0,.37-.12,.37-.36v-3.53c0-1.24-.33-2.2-.98-2.87-.66-.67-1.61-1.01-2.87-1.01h-3.23c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v3.53c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M560.88,130.8h-2.74c-1.75,0-3.13-.5-4.14-1.51-1.01-1-1.52-2.38-1.52-4.12v-8.96c0-1.74,.5-3.12,1.52-4.12,1.01-1,2.39-1.51,4.14-1.51h2.74c2.1,0,3.54,.69,4.3,2.06h.16v-9.23c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48h-1.19c-.27,0-.41-.16-.41-.48v-1.58h-.16c-.76,1.37-2.2,2.06-4.3,2.06Zm4.46-5.67v-8.88c0-1.24-.38-2.2-1.13-2.87-.75-.67-1.78-1.01-3.09-1.01h-2.74c-1.26,0-2.21,.34-2.87,1.03-.66,.69-.98,1.65-.98,2.89v8.8c0,1.24,.33,2.21,.98,2.89,.66,.69,1.61,1.03,2.87,1.03h2.74c1.31,0,2.34-.34,3.09-1.01,.75-.67,1.13-1.63,1.13-2.87Z"
              />
              <path
                className="e"
                d="M587.41,130.8h-8.56c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v5.03c0,.32-.16,.48-.49,.48h-12.12c-.25,0-.37,.12-.37,.36v3.05c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01h8.39c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44Zm-11.88-10.66h10.2c.25,0,.37-.12,.37-.36v-3.53c0-1.24-.33-2.2-.98-2.87-.66-.67-1.61-1.01-2.87-1.01h-3.23c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v3.53c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M591.75,110.6h1.27c.27,0,.45,.16,.53,.48l5.49,17.51h.16l5.45-17.51c.11-.32,.29-.48,.53-.48h1.31c.3,0,.4,.16,.29,.48l-6.18,19.26c-.08,.32-.27,.48-.57,.48h-1.8c-.3,0-.51-.16-.61-.48l-6.18-19.26c-.08-.32,.03-.48,.33-.48Z"
              />
              <path
                className="e"
                d="M624.59,130.8h-8.56c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v5.03c0,.32-.16,.48-.49,.48h-12.12c-.25,0-.37,.12-.37,.36v3.05c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01h8.39c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44Zm-11.88-10.66h10.2c.25,0,.37-.12,.37-.36v-3.53c0-1.24-.33-2.2-.98-2.87-.66-.67-1.61-1.01-2.87-1.01h-3.23c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v3.53c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M632.66,130.8h-1.19c-.27,0-.41-.16-.41-.48v-26.91c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v26.91c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M648.3,130.8h-3.52c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v8.96c0,1.74-.52,3.12-1.56,4.12-1.04,1-2.44,1.51-4.22,1.51Zm-3.36-1.78h3.19c1.26,0,2.22-.34,2.89-1.01,.67-.67,1-1.63,1-2.87v-8.88c0-1.24-.33-2.2-1-2.87-.67-.67-1.63-1.01-2.89-1.01h-3.19c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v8.88c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01Z"
              />
              <path
                className="e"
                d="M661.37,136.71h-1.19c-.27,0-.41-.15-.41-.44v-25.2c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v1.58h.16c.76-1.37,2.18-2.06,4.26-2.06h2.78c1.75,0,3.13,.5,4.14,1.51,1.01,1,1.51,2.38,1.51,4.12v8.96c0,1.74-.51,3.12-1.51,4.12-1.01,1-2.39,1.51-4.14,1.51h-2.78c-2.08,0-3.49-.69-4.26-2.06h-.16v7.53c0,.29-.15,.44-.45,.44Zm4.67-7.69h2.7c1.26,0,2.22-.34,2.89-1.01,.67-.67,1-1.64,1-2.91v-8.8c0-1.27-.33-2.24-1-2.91-.67-.67-1.63-1.01-2.89-1.01h-2.7c-1.31,0-2.34,.34-3.09,1.01-.75,.67-1.13,1.63-1.13,2.87v8.88c0,1.24,.37,2.2,1.13,2.87,.75,.67,1.78,1.01,3.09,1.01Z"
              />
              <path
                className="e"
                d="M682,130.8h-1.19c-.27,0-.41-.16-.41-.48v-19.26c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v1.58h.16c.79-1.37,2.24-2.06,4.34-2.06h1.68c2.4,0,4.07,.93,5,2.77,.41-.92,1.11-1.62,2.09-2.08,.98-.46,1.99-.69,3.03-.69h1.51c1.77,0,3.17,.5,4.18,1.51,1.01,1,1.51,2.38,1.51,4.12v14.11c0,.32-.15,.48-.45,.48h-1.19c-.3,0-.45-.16-.45-.48v-14.03c0-1.27-.33-2.24-.98-2.91s-1.61-1.01-2.87-1.01h-1.52c-1.31,0-2.35,.36-3.11,1.09-.76,.73-1.15,1.71-1.15,2.95v13.91c0,.32-.15,.48-.45,.48h-1.19c-.3,0-.45-.16-.45-.48v-14.03c0-1.27-.33-2.24-.98-2.91s-1.61-1.01-2.87-1.01h-1.64c-1.31,0-2.34,.34-3.09,1.01-.75,.67-1.13,1.63-1.13,2.87v14.07c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M725.78,130.8h-8.56c-1.77,0-3.17-.5-4.2-1.51-1.02-1-1.54-2.38-1.54-4.12v-8.96c0-1.74,.51-3.12,1.54-4.12,1.02-1,2.42-1.51,4.2-1.51h3.52c1.77,0,3.18,.5,4.22,1.51,1.04,1,1.56,2.38,1.56,4.12v5.03c0,.32-.16,.48-.49,.48h-12.12c-.25,0-.37,.12-.37,.36v3.05c0,1.24,.33,2.2,.98,2.87,.66,.67,1.61,1.01,2.87,1.01h8.39c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44Zm-11.88-10.66h10.2c.25,0,.37-.12,.37-.36v-3.53c0-1.24-.33-2.2-.98-2.87-.66-.67-1.61-1.01-2.87-1.01h-3.23c-1.26,0-2.21,.34-2.87,1.01s-.98,1.63-.98,2.87v3.53c0,.24,.12,.36,.37,.36Z"
              />
              <path
                className="e"
                d="M733.64,130.8h-1.19c-.27,0-.41-.16-.41-.48v-19.26c0-.32,.14-.48,.41-.48h1.19c.3,0,.45,.16,.45,.48v1.58h.16c.76-1.37,2.18-2.06,4.26-2.06h2.7c1.75,0,3.13,.5,4.14,1.51,1.01,1,1.52,2.38,1.52,4.12v14.11c0,.32-.14,.48-.41,.48h-1.19c-.3,0-.45-.16-.45-.48v-14.03c0-1.24-.33-2.21-.98-2.89-.66-.69-1.61-1.03-2.87-1.03h-2.66c-1.31,0-2.34,.34-3.09,1.01-.75,.67-1.13,1.63-1.13,2.87v14.07c0,.32-.15,.48-.45,.48Z"
              />
              <path
                className="e"
                d="M753.71,112.38h-2.5c-.3,0-.45-.13-.45-.4v-.95c0-.29,.15-.44,.45-.44h2.5c.25,0,.37-.12,.37-.36v-3.21c0-.32,.15-.48,.45-.48h1.19c.3,0,.45,.16,.45,.48v3.21c0,.24,.12,.36,.37,.36h4.87c.3,0,.45,.15,.45,.44v.95c0,.26-.15,.4-.45,.4h-4.87c-.25,0-.37,.12-.37,.36v12.48c0,1.22,.35,2.15,1.06,2.81,.71,.66,1.71,.99,2.99,.99h1.6c.3,0,.45,.15,.45,.44v.91c0,.29-.15,.44-.45,.44h-1.76c-1.8,0-3.25-.49-4.34-1.47-1.09-.98-1.64-2.32-1.64-4.04v-12.56c0-.24-.12-.36-.37-.36Z"
              />
            </g>
          </g>
        </g>
      </Svg>
    </>
  );
};

const Svg = styled.svg<{ color: Color }>`
  .e {
    fill: ${(props) => (props.color === "WHITE" ? "#fff" : "#000")};
  }

  width: 100%;
  grid-column: 3 / 5;
  grid-row: 4;
  align-self: center;
  @media (min-width: 800px) {
    grid-column: 2 / 6;
    width: auto;
    max-width: 500px;
    justify-self: center;
  }
`;

const Photo = styled.div<{ src: string }>`
  height: 80vw;
  width: 80vw;
  max-width: 150px;
  max-height: 150px;
  align-self: center;
  grid-column: 3 / 5;
  grid-row: 3;
  justify-self: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 5px 20px rgb(0 0 0 / 30%);
  border: 3px solid #fff;
  border-radius: 100%;
  @media (min-width: 800px) {
    height: 30vw;
    width: 30vw;
  }
`;
